<template>
 <div class="declare_title flex">
    <h3 class="title">{{ title }}</h3>
    <div v-show="showMark" class="mark flex pointer" @click="downItem">
      <img :src="img" alt="">
      <span :style="{ color: markTitleColor }">{{ markTitle }}</span>
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: {
      default: '',
      type: String
    },
    markTitle: {
      default: '',
      type: String
    },
    markTitleColor: {
      default: '',
      type: String
    },
    img: {
      default: require('@/assets/images/detail/policyDetail/download.png'),
      type: String
    },
    showMark: {
      default: true,
      type: Boolean
    }
  },
  emits: ['downItem'],
  setup (props, context) {
    const downItem = () => {
      context.emit('downItem')
    }
    return {
      downItem
    }
  }
})
</script>
<style lang='scss' scoped>
.declare_title {
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  .mark {
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    span {
      font-size: 18px;
      font-weight: 400;
    }
  }
}
</style>
