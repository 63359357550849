<template>
  <div id="policiesTitle">
    <div class="left_part">
      <div class="title base_title">{{ title }}</div>
    </div>
    <span v-show="showMore" class="pointer" @click="showMoreAction">查看更多</span>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    showMore: {
      type: Boolean,
      default: false
    },
    showLine: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },

  methods: {
    showMoreAction () {
      this.$emit('showMoreAction')
    }
  }
}

</script>
<style lang='scss' scoped>
#policiesTitle {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: baseline;
  .left_part {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #3c89ff;
  }
}
</style>
