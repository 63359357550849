<template>
   <div id="downItem">
    <ul>
      <li
        v-for="(item, index) in downList"
        :key="index"
        class="flex"
        @click="downAction"
      >
        <img src="~assets/images/detail/policyDetail/link.png" alt="">
        {{ item.filename }}
      </li>
    </ul>
  </div>
</template>
<script lang='ts'>
import { defineComponent, PropType } from 'vue'
interface Idownload {
  filename?: string;
  filepath?: string;
}

export default defineComponent({
  props: {
    downList: {
      type: Array as PropType<Idownload[]>
    }
  },
  emits: ['downItem'],
  setup (props, context) {
    const downAction = () => {
      context.emit('downItem')
    }
    return {
      downAction
    }
  }
})
</script>
<style lang='scss' scoped>
#downItem {
  margin-top: 29px;
  li {
    padding: 15px 0 15px 21px;
    background: #f5f8ff;
    border-radius: 5px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #3c89ff;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 1px;
    }
  }
  li:last-of-type {
    margin-bottom: 0;
  }
}
</style>
