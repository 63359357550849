
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'breadcrumb',
  props: {
    // secondStage/thirdStage 需传title、path、isJump
    secondStage: {
      type: Object,
      default: () => {
        return {
          title: '',
          path: '',
          isJump: false // 是否跳转
        }
      }
    },
    thirdStage: {
      type: Object,
      default: () => {
        return {
          title: '',
          path: '',
          isJump: false
        }
      }
    }
  },
  setup () {
    const router = useRouter()
    function toHome () {
      router.push('/')
    }
    function skipTo (isJump: boolean, path: string) {
      if (!isJump) return
      router.push(path)
    }
    return {
      toHome,
      skipTo
    }
  }
})
