const chooseImg = (area: string) => {
  switch (area) {
    case '宝安区':
      return require('@/assets/images/preciseMatch/baoan.png')
    case '福田区':
      return require('@/assets/images/preciseMatch/futian.png')
    case '光明区':
      return require('@/assets/images/preciseMatch/guangming.png')
    case '龙岗区':
      return require('@/assets/images/preciseMatch/longgang.png')
    case '龙华区':
      return require('@/assets/images/preciseMatch/longhua.png')
    case '罗湖区':
      return require('@/assets/images/preciseMatch/luohu.png')
    case '南山区':
      return require('@/assets/images/preciseMatch/nanshan.png')
    case '大鹏新区':
      return require('@/assets/images/preciseMatch/dapeng.png')
    case '盐田区':
      return require('@/assets/images/preciseMatch/yantian.png')
    case '坪山区':
      return require('@/assets/images/preciseMatch/pingshan.png')
    case '国家':
      return require('@/assets/images/preciseMatch/guojia.png')
    case '广东省':
      return require('@/assets/images/preciseMatch/guangdong.png')
    case '深汕合作区':
      return require('@/assets/images/preciseMatch/shenshan.png')
    default:
      return require('@/assets/images/preciseMatch/shenzhen.png')
  }
}

export default chooseImg
