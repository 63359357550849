
import { defineComponent, PropType } from 'vue'
interface Idownload {
  filename?: string;
  filepath?: string;
}

export default defineComponent({
  props: {
    downList: {
      type: Array as PropType<Idownload[]>
    }
  },
  emits: ['downItem'],
  setup (props, context) {
    const downAction = () => {
      context.emit('downItem')
    }
    return {
      downAction
    }
  }
})
