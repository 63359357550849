<template>
  <div class="detail_head base_width">
    <div
      v-for="item in detailList"
      :key="item.title"
      class="detail_head_content flex"
    >
      <div class="type">
        <img :src="chooseImg(item.area_name)" alt="" />
      </div>
      <div class="center_part flex_col">
        <h1 class="title">{{ item.policy_name }}</h1>
        <div class="tips flex">
          <span v-show="item.source" class="tips1">{{ item.source }}</span>
        </div>
        <div class="other_tips flex">
          <div class="money">
            最高补贴
            <span v-if="item.amount">{{ item.amount }}万</span>
            <span v-else>以最终审批为准</span>
          </div>
          <div class="time">
            申报时间：
            {{ item.release_date }} -
            {{ item.expire_date }}
          </div>
        </div>
      </div>
      <div class="btn_action flex_col">
        <div class="detailBtn pointer btn_click" @click="counsult(item)">
          免费预约申报
        </div>
        <div
          class="detailBtn btn_click"
          @mouseenter="enter"
          @mouseleave="leave"
          v-show="item.phone"
        >
          电话咨询
        </div>
      </div>
      <BaseTips top="-60px" right="20px">
        <span>别犹豫了，免费咨询</span></BaseTips
      >
      <BaseTips :showX="false" top="150px" right="-20px" v-show="showPhone">
        <span>你还可以拨打电话{{ item.phone }}</span></BaseTips
      >
    </div>
    <commonForm ref="commonForm" />
  </div>
</template>
<script>
import BaseTips from '@/common/BaseTips.vue'
import chooseImg from './chooseImg'
import store from '@/store'

export default {
  components: {
    BaseTips
  },
  props: {
    detailList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showPhone: false
    }
  },
  methods: {
    counsult(item) {
      if (store.getters.loginStatus) {
        const { policy_name, pid } = item
        this.$refs.commonForm.show({
          type: '2',
          moreParams: { policyTitle: policy_name, pid: pid.toString() }
        })
      } else {
        this.$router.push('/login?showTips=1&redirect=' + this.$route.fullPath)
      }
    },
    enter() {
      this.showPhone = true
    },
    leave() {
      this.showPhone = false
    },
    chooseImg(area_name) {
      return chooseImg(area_name)
    }
  }
}
</script>
<style lang="scss" scoped>
.detail_head {
  margin: auto;
  padding: 60px 20px 45px 33px;
  background: #ffffff;
  box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
  border-radius: 6px;
  .detail_head_content {
    position: relative;
    img {
      width: 270px;
      // height: 190px;
      margin-right: 12px;
    }
    .center_part {
      width: 589px;
      margin-left: 20px;
      .title {
        margin-top: 9px;
        max-width: 564px;
        font-size: 26px;
        font-weight: bold;
        line-height: 43px;
        color: #333333;
        display: -webkit-box;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        /* autoprefixer: ignore next */
        line-clamp: 2;
        /* autoprefixer: ignore next */
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        /* white-space: nowrap; */
      }
      .tips {
        margin-top: 6px;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          padding: 4px 8px;
          background: #ffffff;
          border: 1px solid #f1f1f1;
          border-radius: 3px;
        }
        .tips1 {
          margin-right: 8px;
        }
      }
      .other_tips {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        align-items: baseline;
        .money {
          white-space: nowrap;
          margin-right: 32px;
          span {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff5b60;
          }
        }
        .time {
          white-space: nowrap;
        }
      }
    }
    .btn_action {
      flex: 1;
      margin-left: 52px;
      .detailBtn {
        width: 189px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        font-weight: 400;
      }
      .detailBtn:first-of-type {
        color: #000;
        font-weight: bold;
        background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
        box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
        border-radius: 25px;
      }
      .detailBtn:last-of-type {
        margin-top: 38px;
        color: #c8a260;
        border: 1px solid #c8a260;
        border-radius: 28px;
        background: #ffffff;
        box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
      }
    }
  }
}
.slot_phone {
  color: #3c89ff;
  font-size: 16px;
  flex-wrap: 400;
}
:deep(.base_tips):last-of-type {
  .shaps {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #000;
    border-top: 8px solid transparent;
    top: -16px;
  }
}
</style>
