<template>
  <div id="policyDetailContent" class="base_width flex">
    <div class="detail_left_part">
      <policyDetailContentItem />
    </div>
    <div class="detail_right_part">
      <!-- 推荐政策 -->
      <div class="qr flex_col">
        <img :src="imgUrl" alt="" />
        <span>打开微信扫码分享给朋友</span>
      </div>
      <RecommendPolicies :recommendList="recommendList" @toDetail="toDetail" />
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, onMounted, ref } from 'vue'
import { selectByIndex } from '@/api/policyList'
import RecommendPolicies from '@/common/recommendPolicies.vue'
import { IrecommendPolicy } from '@/types/policyList'
import policyDetailContentItem from './PolicyDetailContentItem.vue'
import { useRouter, useRoute } from 'vue-router'
import { qrCode } from '@/api/qr'

export default defineComponent({
  components: {
    RecommendPolicies,
    policyDetailContentItem
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const imgUrl = ref(require('@/assets/images/entService/code.png'))
    const recommendList = ref<IrecommendPolicy[]>([])
    const getRecommend = () => {
      selectByIndex({}).then(res => {
        recommendList.value = res.data
      })
    }
    const toDetail = (pid: string) => {
      router.push({ path: '/detail', query: { pid } })
    }
    onMounted(() => {
      getRecommend()
      qrCode({
        url: 'pages/h5PolicyDetail/h5PolicyDetail',
        scene: `pro_7_${route.query.pid}_123456`
        // scene: 'pro_7_204655_123456'
      }).then((res: any) => {
        if (res.status === '1') {
          imgUrl.value = 'data:image/jpeg;base64,' + res.data
        }
      })
    })
    return {
      recommendList,
      toDetail,
      imgUrl
    }
  }
})
</script>
<style lang='scss' scoped>
#policyDetailContent {
  margin: auto;
  .detail_left_part {
    min-width: 845px;
    margin-right: 34px;
  }
  .detail_right_part {
    min-width: 321px;
    flex: 1;
    .qr {
      height: 261px;
      background: #ffffff;
      box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
      opacity: 1;
      border-radius: 6px;
      align-items: center;
      padding-top: 46px;
      img {
        width: 130px;
        height: 130px;
      }
      span {
        margin-top: 13px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
:deep(#recommendPolicies) {
  background: #ffffff;
  box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
  border-radius: 6px;
  margin-top: 34px;
  #policiesTitle {
    margin-bottom: 29px;
    padding: 33px 182px 0 32px;
  }
  .left_part {
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #555555;
    }
  }
  ul {
    margin-top: 0;
    padding: 0 36px 22px 36px;
  }
  li {
    padding: 0;
    border: 0;
    border-bottom: 1px solid #f1f1f1;
    .tips3 {
      margin-bottom: 29px;
    }
  }
}
</style>
