
import { defineComponent, onMounted, ref } from 'vue'
import { selectByIndex } from '@/api/policyList'
import RecommendPolicies from '@/common/recommendPolicies.vue'
import { IrecommendPolicy } from '@/types/policyList'
import policyDetailContentItem from './PolicyDetailContentItem.vue'
import { useRouter, useRoute } from 'vue-router'
import { qrCode } from '@/api/qr'

export default defineComponent({
  components: {
    RecommendPolicies,
    policyDetailContentItem
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const imgUrl = ref(require('@/assets/images/entService/code.png'))
    const recommendList = ref<IrecommendPolicy[]>([])
    const getRecommend = () => {
      selectByIndex({}).then(res => {
        recommendList.value = res.data
      })
    }
    const toDetail = (pid: string) => {
      router.push({ path: '/detail', query: { pid } })
    }
    onMounted(() => {
      getRecommend()
      qrCode({
        url: 'pages/h5PolicyDetail/h5PolicyDetail',
        scene: `pro_7_${route.query.pid}_123456`
        // scene: 'pro_7_204655_123456'
      }).then((res: any) => {
        if (res.status === '1') {
          imgUrl.value = 'data:image/jpeg;base64,' + res.data
        }
      })
    })
    return {
      recommendList,
      toDetail,
      imgUrl
    }
  }
})
