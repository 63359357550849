
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: {
      default: '',
      type: String
    },
    markTitle: {
      default: '',
      type: String
    },
    markTitleColor: {
      default: '',
      type: String
    },
    img: {
      default: require('@/assets/images/detail/policyDetail/download.png'),
      type: String
    },
    showMark: {
      default: true,
      type: Boolean
    }
  },
  emits: ['downItem'],
  setup (props, context) {
    const downItem = () => {
      context.emit('downItem')
    }
    return {
      downItem
    }
  }
})
