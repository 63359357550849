<template>
  <div class="base_tips queue-bottom" v-if="showTips" ref="refTips">
    <slot>想知道你的企业能申报什么扶持政策？</slot>
    <img src="@/assets/images/common/x.png" alt="" @click="showTips = false" class="pointer" v-if="showX"/>
    <div class="shaps"></div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  props: {
    top: {
      type: String,
      default: '0px'
    },
    right: {
      type: String,
      default: '0px'
    },
    showX: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const showTips = ref(true)
    const refTips = ref<null | HTMLElement>(null)
    onMounted(() => {
      if (refTips.value) {
        refTips.value.style.top = props.top
        refTips.value.style.right = props.right
      }
    })
    return {
      showTips,
      refTips
    }
  }
})
</script>
<style lang='scss' scoped>
.base_tips {
  display: inline-block;
  background: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 41px;
  color: #ffffff;
  padding: 0 8px 0 13px;
  position: absolute;
  img {
    display: inline-block;
    margin-left: 7px;
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
  .shaps {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
  }
}
</style>
