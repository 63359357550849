import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 政策详情二维码
export function qrCode(data?: RequestData) {
  return request({
    url: '/pla/qrcode/newCustomerManagerQRCode',
    method: 'post',
    data
  })
}

// 招标详情二维码
export function bidQrCode(data?: RequestData) {
  return request({
    url: '/pla/qrcode/bidsCustomerManagerQRCode',
    method: 'post',
    data
  })
}
