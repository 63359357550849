
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  props: {
    top: {
      type: String,
      default: '0px'
    },
    right: {
      type: String,
      default: '0px'
    },
    showX: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const showTips = ref(true)
    const refTips = ref<null | HTMLElement>(null)
    onMounted(() => {
      if (refTips.value) {
        refTips.value.style.top = props.top
        refTips.value.style.right = props.right
      }
    })
    return {
      showTips,
      refTips
    }
  }
})
