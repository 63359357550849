<template>
  <div id="policyDetailContentItem" class="flex_col">
    <!-- 申报条件 -->
    <div class="declare" v-if="detailList.funding_object">
      <PolicyDetailContentItemTitle
        title="资助对象"
        :show-mark="false"
        mark-title-color="#c8a260"
      />
      <pre class="declare_content" v-html="detailList.funding_object" />
    </div>
    <div class="declare">
      <PolicyDetailContentItemTitle
        title="申报条件"
        :show-mark="false"
        mark-title-color="#c8a260"
      />
      <pre class="declare_content" v-html="detailList.apply_condition" />
    </div>
    <div class="declare" v-if="detailList.funding_object">
      <PolicyDetailContentItemTitle
        title="申报流程"
        :show-mark="false"
        mark-title-color="#c8a260"
      />
      <pre class="declare_content" v-html="detailList.declaration_process" />
    </div>
    <div class="declare" v-if="detailList.funding_object">
      <PolicyDetailContentItemTitle
        title="支持力度"
        :show-mark="false"
        mark-title-color="#c8a260"
      />
      <pre class="declare_content" v-html="detailList.support_strength" />
    </div>
    <div class="declare" v-if="detailList.funding_object">
      <PolicyDetailContentItemTitle
        title="所需材料"
        :show-mark="false"
        mark-title-color="#c8a260"
      />
      <pre class="declare_content" v-html="detailList.materials_needed" />
    </div>
    <!-- 附件 -->
    <div
      v-show="detailList.annexes_list && detailList.annexes_list.length"
      class="enclosure"
    >
      <PolicyDetailContentItemTitle
        title="附件"
        mark-title-color="#3C89FF"
        mark-title="下载全部附件"
        @downItem="downItem(detailList)"
      />
      <BaseDownload
        :down-list="detailList.annexes_list"
        @downItem="downItem(detailList)"
      />
    </div>
    <!-- 温馨提示 -->
    <div class="reminder">
      <PolicyDetailContentItemTitle title="温馨提示" :show-mark="false" />
      <div class="reminder_content flex_col">
        <p>
          1.点击<a
            style="cursor: pointer"
            :href="detailList.url"
            target="_blank"
            rel="nofollow"
            >官方链接</a
          >前往查看详情
        </p>
        <p>2.如有疑问可联系{{ detailList.phone }}({{ detailList.source }})</p>
        <p>3.联系平台工作人员 <span>0755-23984329</span></p>
      </div>
    </div>
    <commonForm ref="commonForm" />
  </div>
</template>
<script>
import PolicyDetailContentItemTitle from './PolicyDetailContentItemTitle.vue'
import { emitter } from '@/mitt/index'
import BaseDownload from '@/common/BaseDownload.vue'
import store from '@/store'

export default {
  components: {
    PolicyDetailContentItemTitle,
    BaseDownload
  },
  data() {
    return {
      detailList: []
    }
  },
  mounted () {
    emitter.on('policyDetailHead', (data) => {
      this.detailList = data
    })
  },
  methods: {
    downItem(item) {
      if (store.getters.loginStatus) {
        const { policy_name, pid } = item
        this.$refs.commonForm.show({
          moreParams: { policyTitle: policy_name, pid: pid.toString() }
        })
      } else {
        this.$router.push('/login?showTips=1&redirect=' + this.$route.fullPath)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
#policyDetailContentItem {
  padding: 0px 41px 50px 41px;
  background: #ffffff;
  box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
  border-radius: 6px;
  .declare{
    margin-top: 48px;
  }
  .declare_content {
    white-space: break-spaces;
    margin-top: 20px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    color: #555555;
  }
  .enclosure {
    margin-top: 46px;
  }
  .reminder {
    margin-top: 60px;
    .reminder_content {
      margin-top: 30px;
      > p {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 30px;
        a,
        span {
          color: #3c89ff;
        }
      }
      & > :last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
