
import { defineComponent, onMounted, ref } from 'vue'
import { IpolicyDetail, Ipid } from '@/types/detail'
import PolicyDetailHead from './components/PolicyDetailHead.vue'
import policyDetailContent from './components/PolicyDetailContent.vue'
import Breadcrumb from '@/common/breadcrumb.vue'
import { detail, noticeDetail } from '@/api/policyDetail'
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute } from 'vue-router'
import { emitter } from '@/mitt/index'

export default defineComponent({
  components: {
    PolicyDetailHead,
    policyDetailContent,
    Breadcrumb
  },
  setup () {
    const route = useRoute()
    const detailList = ref<IpolicyDetail[] | null>([])
    const noticeDetailData = ref<any>({})
    const type = ref<any>('')
    const secondStage = ref({
      title: '惠企政策',
      path: '/policyList',
      isJump: true
    })
    const thirdStage = ref({
      title: '',
      path: '',
      isJump: false
    })
    const getDetail = (params: Ipid) => {
      detail(params).then((res: any) => {
        if (res.data && detailList.value) {
          emitter.emit('policyDetailHead', res.data)
          detailList.value.push(res.data)
          console.log('wqeqewq', res.data)
          thirdStage.value.title = res.data.policy_name
          document.title = res.data.policy_name + '-慧点政策'
        }
      })
    }

    const getNoticeDetail = (params: any) => {
      noticeDetail(params).then((res: any) => {
        if (res.data && noticeDetailData.value) {
          noticeDetailData.value = res.data
        }
      })
    }
    onBeforeRouteUpdate((to, from) => {
      if (to.query && to.query.pid !== from.query.pid) {
        if (to.query.type === 'notice') {
          type.value = 'notice'
          getNoticeDetail({ uid: (to.query.pid) as string })
        } else {
          type.value = 'normal'
          detailList.value = []
          getDetail({ pid: (to.query.pid) as string })
        }
      }
    })
    onBeforeRouteLeave((to, from) => {
      if (to.path === '/policyList' && from.path === '/detail') {
        emitter.emit('fromDetail', true)
      }
    })
    onMounted(() => {
      const pid = route.query.pid as string
      if (pid) {
        if (route.query.type === 'notice') {
          getNoticeDetail({ uid: pid })
        } else {
          detailList.value = []
          getDetail({ pid })
        }
        type.value = route.query.type
      }
    })
    return {
      secondStage,
      thirdStage,
      detailList,
      noticeDetailData,
      type
    }
  }
})
