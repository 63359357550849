<template>
  <div class="breadcrumb">
    <span class="txt">你当前所在位置：</span>
    <span class="locate jump" @click="toHome('first')">首页</span>
    <div
      class="stage"
      v-if="secondStage.title"
      @click="skipTo(secondStage.isJump, secondStage.path)"
    >
      <img class="arrow" src="@/assets/images/common/arrowRight.png" alt="" />
      <span class="locate" :class="{ jump: secondStage.isJump }">{{
        secondStage.title
      }}</span>
    </div>
    <div
      class="stage"
      v-if="thirdStage.title"
      @click="skipTo(thirdStage.isJump, thirdStage.path)"
    >
      <img class="arrow" src="@/assets/images/common/arrowRight.png" alt="" />
      <span class="locate" :class="{ jump: thirdStage.isJump }">{{
        thirdStage.title
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'breadcrumb',
  props: {
    // secondStage/thirdStage 需传title、path、isJump
    secondStage: {
      type: Object,
      default: () => {
        return {
          title: '',
          path: '',
          isJump: false // 是否跳转
        }
      }
    },
    thirdStage: {
      type: Object,
      default: () => {
        return {
          title: '',
          path: '',
          isJump: false
        }
      }
    }
  },
  setup () {
    const router = useRouter()
    function toHome () {
      router.push('/')
    }
    function skipTo (isJump: boolean, path: string) {
      if (!isJump) return
      router.push(path)
    }
    return {
      toHome,
      skipTo
    }
  }
})
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  .txt {
    color: #999999;
  }
  .locate {
    max-width: 500px;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .jump {
    cursor: pointer;
    &:hover {
      color: #e5ba71;
    }
  }
  .stage {
    display: flex;
    align-items: center;
    .arrow {
      width: 15px;
      height: 15px;
      margin: 0 4px;
    }
  }
}
</style>
