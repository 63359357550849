<template>
  <div id="recommendPolicies">
    <policiesTitle title="推荐政策" />
    <ul>
      <li
        v-for="(item, index) in recommendList"
        :key="index"
        class="pointer"
        @click="toDetail(item.pid)"
      >
        <div class="title">{{ item.policyTitle }}</div>
        <div v-show="item.labOne" class="tips tips1">{{ item.labOne }}</div>
        <div v-show="item.labTwo" class="tips tips2">{{ item.labTwo }}</div>
        <div v-if="item.amount && item.amount !== '0'" class="tips3">
          最高补助{{ Number(item.amount) }}万
        </div>
        <div v-else class="tip3">以最终审批为准</div>
      </li>
    </ul>
  </div>
</template>

<script>
import policiesTitle from '@/common/policiesTitle'

export default {
  components: {
    policiesTitle
  },
  props: {
    recommendList: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    toDetail(pid) {
      this.$emit('toDetail', pid)
    }
  }
}
</script>
<style lang='scss' scoped>
#recommendPolicies {
  margin-top: 8px;
}
ul {
  margin-top: 24px;
  li {
    // background: linear-gradient(90deg, rgba(60, 137, 255, 0.1) 0%, rgba(252, 252, 252, 0.1) 100%);
    padding: 15px;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    .title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #444444;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .tips {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-top: 16px;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      border-radius: 3px;
      align-self: baseline;
      padding: 4px 9px;
    }
    .tips3 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      color: #ff5b60;
      margin-top: 20px;
      font-weight: bold;
    }
  }
  li:last-of-type {
    margin-bottom: 0;
  }
  li:hover{
    box-shadow: 0px 6px 48px rgba(0, 0, 0, 0.04);
  }
}
</style>
